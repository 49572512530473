import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../style/carousel.sass';

const VerticalCarousel = ({ items, currentSlide, onSlideChange }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: false,
    arrows: false,
  };

  const [currentCardIndex, setCurrentCardIndex] = useState(currentSlide ?? 0);
  const slidesRef = useRef([]);
  const isScrollingRef = useRef(false);

  useEffect(() => {
    setCurrentCardIndex(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    if (slidesRef.current[currentCardIndex]) {
      slidesRef.current.forEach((slide, index) => {
        slidesRef.current[index].style.backgroundColor = currentCardIndex === index ? items[currentCardIndex].hoverBackgroundColor : items[currentCardIndex].backgroundColor;
      });
    }
  }, [currentCardIndex, items]);

  const handleMouseClick = (index, e) => {
    if (slidesRef.current[currentCardIndex]) {
      slidesRef.current[currentCardIndex].style.backgroundColor = items[currentCardIndex].backgroundColor;
    }

    setCurrentCardIndex(index);
    onSlideChange(index);
    e.currentTarget.style.backgroundColor = items[index].hoverBackgroundColor;
  };

  const handleScroll = (e) => {
    e.preventDefault();

    if (isScrollingRef.current) return;

    isScrollingRef.current = true;

    setTimeout(() => {
      if (e.deltaY > 0) {
        // Scrolling down
        if (currentCardIndex < items.length - 1) {
          setCurrentCardIndex((prev) => {
            const newIndex = prev + 1;
            onSlideChange(newIndex);
            return newIndex;
          });
        } else {
          scrollToSection('next-section');
        }
      } else if (e.deltaY < 0) {
        // Scrolling up
        if (currentCardIndex > 0) {
          setCurrentCardIndex((prev) => {
            const newIndex = prev - 1;
            onSlideChange(newIndex);
            return newIndex;
          });
        } else {
          scrollToSection('previous-section');
        }
      }
      isScrollingRef.current = false;
    }, 500); // Adjust the debounce time as necessary
  };

  useEffect(() => {
    const carouselContainer = document.querySelector('.vertical-container');
    carouselContainer.addEventListener('wheel', handleScroll);

    return () => {
      carouselContainer.removeEventListener('wheel', handleScroll);
    };
  }, [currentCardIndex]);

  const scrollToSection = (sectionId) => {
    const header = document.getElementById('header-navbar');
    const headerHeight = header ? header.offsetHeight : 0;
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: sectionTop - headerHeight,
        behavior: 'smooth',
      });
    }
  };
  
  return (
    <section className="vertical-container section3">
      <div
        className="carousel-container fit-page"
        style={{ backgroundImage: `url(${items[currentSlide].backgroundImage.images.fallback.src})` }}
      >
        <div className="slider-container vertical-slider">
          <Slider {...settings} style={{marginLeft:'6%'}}>
            {items.map((item, index) => {
            return(
              <div
                key={item.id || index}
                ref={(el) => (slidesRef.current[index] = el)}
                className="carousel-boxs"
                onClick={(e) => handleMouseClick(index, e)}
              >
                <GatsbyImage image={item.image} alt={item.caption} />
                <p style={{ fontSize: '0.6rem', fontWeight: "500" }}>{item.caption}</p>
              </div>
            )})}
          </Slider>
        </div>
        <div className="content-container">
          <div>
            <h2>{items[currentSlide].title}</h2>
            <p>{items[currentSlide].description}</p>
            <button className="button is-primary" style={{ display: 'flex', width: 'auto', padding: '1rem' , fontSize: 'clamp(0.6rem , 1.3vw , 1rem)', backgroundColor: '#E65E27'}}>
              {items[currentSlide].button}
            </button>
          </div>
          <div className="screens">
            <GatsbyImage className="screen" image={items[currentSlide].screen1} alt="Screen 1" style={{position: items[currentSlide].positionOne , zIndex: items[currentSlide].zIndexOne , top: items[currentSlide].topOne , left: items[currentSlide].leftOne}}/>
            <GatsbyImage className="screen" image={items[currentSlide].screen2} alt="Screen 2" style={{position: items[currentSlide].positionTwo , zIndex: items[currentSlide].zIndexTwo , top: items[currentSlide].topTwo , left: items[currentSlide].leftTwo}}/>
            <GatsbyImage className="screen" image={items[currentSlide].screen3} alt="Screen 3" style={{position: items[currentSlide].positionThree , zIndex: items[currentSlide].zIndexThree , top: items[currentSlide].topThree , left: items[currentSlide].leftThree}}/>
          </div>
        </div>
      </div>
    </section>
  );
};

VerticalCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      screen1: PropTypes.object.isRequired,
      positionOne: PropTypes.string.isRequired,
      zIndexOne: PropTypes.string.isRequired,
      topOne: PropTypes.string.isRequired,
      leftOne: PropTypes.string.isRequired,
      screen2: PropTypes.object.isRequired,
      positionTwo: PropTypes.string.isRequired,
      zIndexTwo: PropTypes.string.isRequired,
      topTwo: PropTypes.string.isRequired,
      leftTwo: PropTypes.string.isRequired,
      screen3: PropTypes.object.isRequired,
      positionThree: PropTypes.string.isRequired,
      zIndexThree: PropTypes.string.isRequired,
      topThree: PropTypes.string.isRequired,
      leftThree: PropTypes.string.isRequired,
      backgroundImage: PropTypes.object.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentSlide: PropTypes.number.isRequired,
  onSlideChange: PropTypes.func.isRequired,
};

export default VerticalCarousel;